/* eslint-disable react/prop-types */
import * as React from 'react';
import styled from 'styled-components/macro';
import { graphql, useStaticQuery } from 'gatsby';
import { Typography } from 'components/DesignSystemV2/Typography';
import { useLocation } from '@reach/router';
import { fromScreen } from 'utils/media-query/responsive.util';
import Lightbox from 'react-image-lightbox';
import { getAssetLinkByHandle } from 'utils/getAssetLink';
import { Card } from './components/Card';
import { SelectWithPrefix } from 'shared/SchedulePage/components/SelectWithPrefix';

const Container = styled.div`
  margin-top: 32px;
  .intro {
    margin-top: 12px;
    margin-bottom: 24px;
  }
  ${fromScreen(776)} {
    margin-top: 100px;
  }
`;

export const CardList = styled.div`
  margin-top: 16px;
  display: grid;
  gap: 24px;

  ${fromScreen(776)} {
    grid-template-columns: 1fr 1fr;
    gap: 24px;
  }
  ${fromScreen(1144)} {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

export const BranchLocation = ({ title, desc }) => {
  const data = useStaticQuery(graphql`
    {
      gcms {
        jsonContanier(where: { name: "Branch_Location" }) {
          content
        }
      }
    }
  `);
  return (
    <Container>
      <Typography
        variant="bold/24-32"
        tabletVariant="bold/32-40"
        desktopVariant="bold/40-48"
        v3
        as="h2"
      >
        {title}
      </Typography>
      <Typography
        variant="regular/16-24"
        desktopVariant="regular/20-28"
        v3
        className="intro"
      >
        {desc}
      </Typography>
      <ListOffice branchList={data.gcms.jsonContanier.content.branch} />
    </Container>
  );
};

const ListOffice = ({ branchList }) => {
  const [isOpen, setOpen] = React.useState(false);
  const [index, setIndex] = React.useState(0);
  const [images, setImages] = React.useState([]);

  const location = useLocation();
  const centerQueryValue = new URLSearchParams(location.search).get('center');
  const centerQueryLabel = branchList.find(
    item => item.id === centerQueryValue
  )?.title;
  const [selectedBranch, setSelectedBranch] = React.useState(
    centerQueryLabel
      ? {
          label: centerQueryLabel,
          value: centerQueryValue
        }
      : null
  );

  const options = React.useMemo(() => {
    return [
      { label: 'All', value: null },
      ...branchList.map(eachBranch => ({
        value: eachBranch.id,
        label: eachBranch.title.replace('Cơ sở', '')
      }))
    ];
  }, [branchList]);

  const data = React.useMemo(() => {
    if (selectedBranch?.value) {
      return branchList.filter(
        eachBranch => eachBranch.id === selectedBranch?.value
      );
    }
    return branchList;
  }, [selectedBranch, branchList]);

  return (
    <>
      <SelectWithPrefix
        title="Cơ sở:"
        defaultValue={null}
        options={options}
        onChange={updatedValue => {
          setSelectedBranch(updatedValue);
          window.history.replaceState(
            null,
            '',
            `${window.location.pathname}?center=${updatedValue.value}`
          );
        }}
        value={selectedBranch}
      />
      <CardList>
        {data.map((center, idx) => {
          return (
            <Card
              key={idx}
              item={center}
              canViewImage={Boolean(center?.images.length)}
              onViewImage={() => {
                setImages(center?.images);
                setOpen(true);
              }}
            />
          );
        })}
      </CardList>
      {isOpen && (
        <Lightbox
          reactModalStyle={{
            overlay: {
              zIndex: 200000
            }
          }}
          mainSrc={getAssetLinkByHandle({
            handle: images[index]
          })}
          nextSrc={getAssetLinkByHandle({
            handle: images[(index + 1) % images.length]
          })}
          prevSrc={getAssetLinkByHandle({
            handle: images[(index + images.length - 1) % images.length]
          })}
          onCloseRequest={() => {
            setOpen(false);
            setIndex(0);
          }}
          onMovePrevRequest={() =>
            setIndex((index + images.length - 1) % images.length)
          }
          onMoveNextRequest={() => setIndex((index + 1) % images.length)}
        />
      )}
    </>
  );
};

/* eslint-disable react/prop-types */
import * as React from 'react';
import styled from 'styled-components/macro';
import { Link } from 'gatsby';
import { Typography } from 'components/DesignSystemV2/Typography';
import { colorsV2 } from 'style/colors-v2';
import { LazyImage } from 'components/LazyImage';
import IconSystemV2CommunicationSocialMessenger from '../icons/IconSystemV2CommunicationSocialMessenger';
import { fromScreen } from 'utils/media-query/responsive.util';

const TopInfo = styled.div`
  display: grid;
  gap: 12px;
  grid-template-columns: 56px 1fr;
`;

const TextLink = styled.div`
  display: grid;
  grid-auto-flow: column;
  justify-content: start;
  align-items: center;
  gap: 6px;
  svg {
    font-size: 16px;
  }
  color: ${colorsV2.primary100};
`;

const Main = styled.div`
  border: 1px solid ${colorsV2.gray20};
  border-radius: 8px;
  .content {
    display: grid;
    gap: 16px;
    align-items: start;
    .location,
    .desc {
      min-height: 40px;
    }
    iframe {
      width: 100%;
      border: none;
    }
    .top-info {
      padding: 12px;
      .title {
        display: grid;
        gap: 4px;
      }
    }

    .footer {
      padding: 0 12px 12px;
      .view-image {
        cursor: pointer;
        margin-top: 4px;
      }
    }
  }
  ${fromScreen(776)} {
    .top-info {
      padding: 24px;
    }
    .footer {
      padding: 0 24px 24px;
    }
  }
`;

export const Card = ({ item, onViewImage, canViewImage }) => {
  const { imgHandle, title, linkContact, desc, mapLink, location } = item || {};
  return (
    <Main>
      <div className="content">
        <TopInfo className="top-info">
          <LazyImage handle={imgHandle} />
          <div className="title">
            <Typography variant="bold/16-24">{title}</Typography>
            <Typography variant="regular/14-20" className="location">
              {location}
            </Typography>
            <Link
              rel="nofollow noreferrer noopener"
              target="_blank"
              href={linkContact}
            >
              <TextLink>
                <IconSystemV2CommunicationSocialMessenger />
                <Typography variant="semi-bold/14-20" color="primary100">
                  Inbox Facebook
                </Typography>
              </TextLink>
            </Link>
          </div>
        </TopInfo>
        <iframe
          src={mapLink}
          height="196"
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
        <div className="footer">
          <Typography variant="regular/14-20" className="desc">
            {desc}
          </Typography>
          {canViewImage && (
            <Typography
              variant="semi-bold/14-20"
              color={colorsV2.blue100}
              className="view-image"
              onClick={onViewImage}
            >
              Xem hình ảnh
            </Typography>
          )}
        </div>
      </div>
    </Main>
  );
};

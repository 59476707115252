import * as React from 'react';

function IconSystemV2CommunicationSocialMessenger(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.9974 0.666504C4.02183 0.666504 0.664062 3.61576 0.664062 7.41689C0.664062 9.53379 1.71959 11.3918 3.32979 12.6157C3.32975 12.9042 3.32997 13.2247 3.33022 13.5646L3.33022 13.565C3.33047 13.9162 3.33073 14.288 3.33073 14.6665C3.33073 14.8972 3.45004 15.1116 3.64614 15.2331C3.84225 15.3547 4.08728 15.3662 4.29393 15.2636L6.71261 14.0624C7.12944 14.1306 7.55827 14.1673 7.9974 14.1673C11.973 14.1673 15.3307 11.218 15.3307 7.41689C15.3307 3.61576 11.973 0.666504 7.9974 0.666504ZM1.9974 7.41689C1.9974 4.46808 4.63666 1.99984 7.9974 1.99984C11.3581 1.99984 13.9974 4.46808 13.9974 7.41689C13.9974 10.3657 11.3581 12.834 7.9974 12.834C7.56662 12.834 7.14666 12.7915 6.73915 12.7129C6.59574 12.6853 6.44722 12.7055 6.31642 12.7704L4.66357 13.5913L4.66355 13.5614V13.5614C4.66321 13.0822 4.66289 12.6428 4.66332 12.276C4.66358 12.056 4.55522 11.85 4.37376 11.7255C2.91775 10.7268 1.9974 9.16538 1.9974 7.41689ZM11.5606 6.88517C11.8552 6.66428 11.9149 6.24642 11.694 5.95185C11.4731 5.65728 11.0553 5.59755 10.7607 5.81844L8.68123 7.37778L7.97676 6.3288C7.87483 6.17701 7.71503 6.07375 7.53476 6.04319C7.3545 6.01262 7.16959 6.05744 7.02332 6.16714L4.43398 8.10914C4.13943 8.33005 4.07974 8.74792 4.30065 9.04247C4.52156 9.33703 4.93943 9.39672 5.23398 9.17581L7.26144 7.65521L7.96587 8.70415C8.0678 8.85593 8.22759 8.95919 8.40785 8.98976C8.5881 9.02032 8.773 8.97552 8.91927 8.86584L11.5606 6.88517Z"
        fill="currentColor"
      />
    </svg>
  );
}

const MemoIconSystemV2CommunicationSocialMessenger = React.memo(
  IconSystemV2CommunicationSocialMessenger
);
export default MemoIconSystemV2CommunicationSocialMessenger;
